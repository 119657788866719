@mixin marginHorizontal($amount) {
  margin-left: $amount * 1px;
  margin-right: $amount + 1px;
}

@mixin marginVertical($amount) {
  margin-top: $amount * 1px;
  margin-bottom: $amount * 1px;
}

@mixin paddingHorizontal($amount) {
  padding-left: $amount * 1px;
  padding-right: $amount * 1px;
}

@mixin paddingVertical($amount) {
  padding-top: $amount * 1px;
  padding-bottom: $amount * 1px;
}

@mixin flex() {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin grid() {
  display: grid;
  display: -moz-grid;
  display: -ms-grid;
}

@mixin row() {
  @include flex();
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin rowreverse() {
  @include flex();
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

@mixin column() {
  @include flex();
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@mixin columnreverse() {
  @include flex();
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@mixin bounceTop() {
  transition: transform 0.3s ease-out;
  &:hover {
    transform: translate(0, -10px);
  }
}
