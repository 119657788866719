@import 'styles/Theme.scss';

.container {
  @include grid();
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  row-gap: 64px;
  justify-items: center;

  @include for-size(mobile-portrait) {
    grid-template-columns: 1fr 1fr;
    row-gap: 32px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  @include for-size(mobile-landscape) {
    grid-template-columns: 1fr 1fr;
    margin-top: 2rem;
  }
  @include for-size(tablet-portrait) {
    grid-template-columns: 1fr 1fr;
  }
  img {
    @include for-size(mobile-portrait) {
      width: calc(min(100%, 96px * 1.25));
    }
    @include for-size(mobile-landscape) {
    }
    @include for-size(tablet-portrait) {
    }
    @include for-size(tablet-landscape) {
    }
    @include for-size(desktop) {
    }
    width: calc(min(100%, 128px * 1.1));
  }
}
