@import 'styles/Theme.scss';

.linkWrapper {
  &:focus,
  &:active,
  &:visited {
    border: none;
    outline: none;
  }
}

.container {
  @include grid();
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 520px;
  &.even {
    grid-template-areas: 'a b';
  }
  &.odd {
    grid-template-areas: 'b a';
  }

  @include for-size(mobile-portrait) {
    grid-template-rows: 420px;
    grid-template-columns: 1fr;
  }
  @include for-size(mobile-landscape) {
    grid-template-columns: 1fr;
    grid-template-rows: 420px;
  }

  .imgContainer {
    grid-area: a;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding-right: 10%;
    @include for-size(mobile-portrait) {
      padding-right: 0%;
    }
    @include for-size(mobile-landscape) {
      padding-right: 0%;
    }

    .date {
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $WHITE;
      line-height: 1.5rem;
      padding-top: 16px;
      padding-bottom: 12px;
      @include paddingHorizontal(16);
      color: $PRIMARY;
    }
    .nxtimage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .dataContainer {
    grid-area: b;
    @include column();
    height: 100%;
    justify-content: flex-end;
    @include for-size(mobile-portrait) {
      @include paddingHorizontal(0);
    }
    @include for-size(mobile-landscape) {
      @include paddingHorizontal(0);
    }
    h4 {
      @include for-size(mobile-portrait) {
        margin-top: 20px;
      }
      @include for-size(mobile-landscape) {
        margin-top: 20px;
      }

      margin-bottom: 16px;
      font-weight: 800;
    }
    .author {
      color: $GRAY200;
      margin-bottom: 8px;
      @include for-size(mobile-portrait) {
        display: none;
      }
      @include for-size(mobile-landscape) {
        display: none;
      }
      @include for-size(tablet-portrait) {
        display: none;
      }
    }
    .link {
      @include row();
      width: 100%;
      @include marginVertical(32);
      color: $SECONDARY;
      width: fit-content;
      align-items: center;
      svg {
        margin-left: 16px;
      }
    }
  }
}
