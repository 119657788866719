@import 'styles/Theme.scss';

.servicesWrapper {
  @include column();
  align-items: center;
  @include sectionPaddings();
  gap: 32px;
  max-width: 1440px;
  margin: 0 auto;
  .serviceRow {
    @include grid();
    grid-template-columns: 1fr 1fr;
    @include for-size(mobile-portrait) {
      grid-template-columns: auto;
      grid-template-areas:
        'a'
        'b';
    }
    @include for-size(mobile-landscape) {
      grid-template-columns: auto;
      grid-template-areas:
        'a'
        'b';
    }
    @include for-size(tablet-portrait) {
      grid-template-areas: 'a b';
      &.odd {
        grid-template-areas: 'b a';
      }
    }
    @include for-size(tablet-landscape) {
      grid-template-areas: 'a b';
      &.odd {
        grid-template-areas: 'b a';
      }
    }
    @include for-size(desktop) {
      grid-template-areas: 'a b';
      &.odd {
        grid-template-areas: 'b a';
      }
    }
    @include bounceTop();
    .image {
      grid-area: a;
      @include row();
      justify-content: center;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .data {
      @include column();
      justify-content: center;
      align-items: center;
      grid-area: b;
      .wrapper {
        max-width: 500px;
        @include paddingVertical(16);
        p {
          @include marginVertical(32);
        }
        span {
          color: $SECONDARY;
          svg {
            @include marginHorizontal(16);
          }
        }
      }
    }
  }

  .slideInLeft {
    animation: slideInLeft 1s linear forwards;
  }
  .slideInRight {
    animation: slideInRight 1s ease-in-out forwards;
  }
  @keyframes slideInLeft {
    0% {
      transform: translateX(-50%);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  @keyframes slideInRight {
    0% {
      transform: translateX(50%);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
}
