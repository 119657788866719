@import 'styles/Theme.scss';

.bannerSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-image: url('/images/home-banner.webp');
  background-image: url('/images/home-banner-eunoia-digital.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  background-color: #212529;
  @include for-size(mobile-portrait) {
    background-position-x: center;
    background-size: cover;
  }
  @include for-size(mobile-landscape) {
    background-position-x: center;
    background-size: cover;
  }
  @include for-size(tablet-portrait) {
    background-position-x: center;
    background-size: cover;
  }
  @include for-size(tablet-landscape) {
  }
  @include for-size(desktop) {
  }
}
.bannerSection {
  position: relative;
  height: calc(100vh - #{$DESKTOPHEAD} - 16px);
  @include for-size(mobile-portrait) {
    height: calc(max(100vh - #{$MOBILEHEAD} - 64px, 420px));
  }
  @include for-size(mobile-landscape) {
    height: calc(max(100vh - #{$MOBILEHEAD} - 64px, 420px));
  }
  @include for-size(tablet-portrait) {
    height: calc(max(100vh - #{$MOBILEHEAD} - 64px, 420px));
  }

  @include column();
  .conocenosLink {
    background-color: $WHITE;
    position: absolute;
    bottom: 0;
    z-index: 2;
    right: 0;
    padding-top: 16px;
    padding-bottom: 12px;
    @include paddingHorizontal(16);
    @include row();
    align-items: center;
    &:hover {
      cursor: pointer;

      font-weight: 500;
    }
    svg {
      margin-left: 2rem;
    }
  }
  .content {
    position: relative;
    @include column();
    justify-content: center;
    height: 100%;
    padding-left: calc(min(128px, 15%));
    @include for-size(mobile-portrait) {
      padding-left: calc(min(64px, 10%));
    }
    @include for-size(mobile-landscape) {
    }
    @include for-size(tablet-portrait) {
    }
    @include for-size(tablet-landscape) {
    }
    @include for-size(desktop) {
    }
    .caption {
      @include caption;
      max-width: 340px;
      color: #212529;
      margin-bottom: 16px;
    }
    h1 {
      // max-width: 340px;
      color: #212529;
      font-weight: 300;
      margin-bottom: 16px;
      font-size: 70px;
      line-height: 80px;
      font-weight: bold;
      @include for-size(mobile-portrait) {
        font-size: 33px;
        line-height: 40px;
      }
      @include for-size(mobile-landscape) {
        font-size: 33px;
        line-height: 40px;
      }
    }
    .subtitle {
      max-width: 340px;
      color: #212529;
    }
  }
}

.clientesSection,
.serviciosSection,
.casesSection,
.testimonialsSection,
.blogSection,
.newsletterSection {
  position: relative;
}

.clientesSection {
  @include sectionPaddings();
  h2 {
    margin-bottom: 32px;
  }
}

.serviciosSection {
  .head {
    @include sectionPaddings();
    background-color: $NEUTRAL;
  }
}

.casesSection {
  background: linear-gradient(180deg, $NEUTRAL 0%, $NEUTRAL 60%, $WHITE 60%);
  .head {
    @include sectionPaddings();
  }
  .sampleCase {
    max-width: 1440px;
    margin: 0 auto;
    @include sectionPaddings();
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 32px;

    @include for-size(mobile-portrait) {
      height: calc(min(440px, 90vh));
    }
    @include for-size(mobile-landscape) {
      height: calc(min(620px, 75vh));
    }
    @include for-size(tablet-portrait) {
      height: 75vh;
    }
    @include for-size(tablet-landscape) {
      height: 80vh;
    }
    @include for-size(desktop) {
      height: calc(min(580px, 90vh));
    }
  }
}

.blogSection {
  .head {
    @include sectionPaddings();
    background-color: $NEUTRAL;
    min-height: 240px;
    padding-bottom: 4rem !important;
    margin-bottom: -2rem;
  }
  .lastGrid {
    @include column();
    @include sectionPaddings();
    padding-top: 0px !important;
    gap: 32px;
  }
}

.newsletterSection {
  @include sectionPaddings();
  background-color: $NEUTRAL;
  h2 {
    padding-right: 16px;
  }
  form {
    @include row();
    @include marginVertical(16);
    input {
      @include paddingVertical(16);
      @include paddingHorizontal(16);
    }

    button {
      @include paddingHorizontal(16);
      background-color: $SECONDARY;
      color: $WHITE;
    }
  }
}
