@import 'styles/Theme.scss';

.root {
  position: relative;
  height: 100%;
  width: auto;
  img {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .box {
    z-index: 2;
    position: absolute;
    left: 0;
    bottom: 0;
    @include column();
    @include paddingHorizontal(32);
    padding-top: 32px;
    width: calc(95% - 64px);
    max-width: 360px;
    background-color: $WHITE;
    color: $BLACK;
    .title {
      @include h4();
      font-weight: 500;
    }
    p {
      @include marginVertical(8);
    }
    .link {
      margin-top: 8px;
      margin-bottom: 16px;
      color: $SECONDARY;
      svg {
        margin-left: 16px;
      }
    }
  }
}
