@mixin for-size($size) {
  @if $size == mobile-portrait {
    @media only screen and (max-width: 420px) {
      @content;
    }
  } @else if $size == mobile-landscape {
    @media only screen and (min-width: 421px) and (max-width: 767px) {
      @content;
    }
  } @else if $size == tablet-portrait {
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $size == tablet-landscape {
    @media only screen and (min-width: 1024px) and (max-width: 1365px) {
      @content;
    }
  } @else if $size == desktop {
    @media only screen and (min-width: 1365px) {
      @content;
    }
  }
}
