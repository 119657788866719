@import 'Helpers.scss';
@import 'Responsive.scss';

// FONT FAMILY

$REGULARFONT: 'PP Neue Montreal';
// PP Neue Montreal
$TITLEFONT: 'ppneuemontreal';

// CONSTANTS
$DESKTOPHEAD: 72px;
$MOBILEHEAD: 64px;

// PRIMARY COLORS

$PRIMARY: #122b3f;
$SECONDARY: #3dbfee;
$NEUTRAL: #f7f7f7;
$DESIGN: #e7bb7c;
$DEVELOPMENT: #7ce1e7;
$MARKETING: #9bc5a2;
$IMPLANT: #f2b9df;

// NEUTRALS
$BLACK: #000000;
$GRAY800: #4e4e4e;
$GRAY400: #6f6f6f;
$GRAY200: #9a9a9a;
$GRAY100: #dedede;
$GRAY50: #f9f9f9;
$WHITE: #ffffffff;

$TRANSPARENT: rgba(0, 0, 0, 0);

// COMMON STYLES DEFINITIOSN

@mixin h1 {
  font-family: $TITLEFONT;
  font-size: 2.75rem;
  line-height: 48px;
  white-space: pre-wrap;
  white-space: pre-wrap;
  @include for-size(mobile-portrait) {
    font-size: 2.2rem;
    line-height: 36px;
  }
  @include for-size(mobile-landscape) {
  }
}

@mixin h2 {
  white-space: pre-wrap;
  font-family: $TITLEFONT;
  font-size: 2.5rem;
  line-height: 50px;
  @include for-size(mobile-portrait) {
    font-size: 1.625rem;
    line-height: 32px;
  }
  @include for-size(mobile-landscape) {
    font-size: 1.625rem;
    line-height: 32px;
  }
}
@mixin h3 {
  white-space: pre-wrap;
  font-family: $REGULARFONT;
  font-size: 2rem;
  line-height: 38px;
  font-weight: 800;
  @include for-size(mobile-portrait) {
    font-size: 1.375rem;
    line-height: 32px;
  }
  @include for-size(mobile-landscape) {
    font-size: 1.375rem;
    line-height: 32px;
  }
}
@mixin h4 {
  white-space: pre-wrap;
  font-family: $REGULARFONT;
  font-size: 1.375rem;
  line-height: 30px;
  font-weight: 500;
  @include for-size(mobile-portrait) {
    font-size: 1.125rem;
    line-height: 26px;
  }
  @include for-size(mobile-landscape) {
    font-size: 1.125rem;
    line-height: 26px;
  }
}

@mixin strong() {
  font-weight: 600;
  font-size: 1rem;
  line-height: 30px;
}

@mixin normal() {
  font-weight: normal;
  font-size: 1rem;
  line-height: 30px;
}

@mixin small() {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 20px;
}

@mixin caption() {
  font-family: $REGULARFONT;
  font-size: 0.875rem;
  font-weight: 800;
  color: $GRAY800;
}

@mixin sectionPaddings {
  @include for-size(mobile-portrait) {
    padding-left: calc(min(10%, 20px));
    padding-right: calc(min(10%, 20px));
    @include paddingVertical(32);
  }
  @include for-size(mobile-landscape) {
    @include paddingVertical(64);
    padding-left: calc(min(10%, 32px));
    padding-right: calc(min(10%, 32px));
  }
  @include for-size(tablet-portrait) {
    @include paddingVertical(64);
    padding-left: calc(min(10%, 32px));
    padding-right: calc(min(10%, 32px));
  }
  @include for-size(tablet-landscape) {
    @include paddingVertical(128);
    padding-left: calc(min(10%, 32px));
    padding-right: calc(min(10%, 32px));
  }
  @include for-size(desktop) {
    @include paddingVertical(128);
    @include paddingHorizontal(156);
  }
}
