@import 'styles/Theme.scss';

.container {
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1min-content 1fr;
  gap: 1rem 2rem;
  grid-template-areas:
    'a b'
    'c d';
  @include for-size(mobile-portrait) {
    row-gap: 6px;

    grid-template-columns: 1fr;
    grid-template-areas:
      'a'
      'b'
      'c'
      'd';
  }
  @include for-size(mobile-landscape) {
    row-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-areas:
      'a'
      'b'
      'c'
      'd';
  }
  @include for-size(tablet-portrait) {
  }
  @include for-size(tablet-landscape) {
  }
  @include for-size(desktop) {
  }
  .a {
    grid-area: a;
  }
  .b {
    grid-area: b;
  }
  .c {
    grid-area: c;
    padding-right: calc(min(10%, 4rem));
  }
  .d {
    grid-area: d;
  }
}
