@import 'styles/Theme.scss';
.container {
  @include sectionPaddings();
  .gridContainer {
    padding: 1rem;
    background: linear-gradient(
      90deg,
      rgba($WHITE, 1) 25%,
      rgba($NEUTRAL, 1) 25%,
      rgba($NEUTRAL, 1) 100%
    );

    @include for-size(mobile-portrait) {
      background: linear-gradient(
        180deg,
        rgba($WHITE, 1) 25%,
        rgba($NEUTRAL, 1) 25%,
        rgba($NEUTRAL, 1) 100%
      );
    }
    @include for-size(mobile-landscape) {
      background: linear-gradient(
        180deg,
        rgba($WHITE, 1) 30%,
        rgba($NEUTRAL, 1) 30%,
        rgba($NEUTRAL, 1) 100%
      );
    }

    .carouselContainer {
      padding-top: 4rem;
      @include for-size(mobile-portrait) {
        height: calc(min(80vh, 420px));
      }
      @include for-size(mobile-landscape) {
        height: calc(min(70vh, 3, 420px));
        @include paddingHorizontal(16);
        margin: 0 auto;
      }
      @include for-size(tablet-portrait) {
        height: calc(min(70vh, 380px));
      }
      @include for-size(tablet-landscape) {
        height: calc(min(70vh, 380px));
      }
      height: calc(min(70vh, 320px));
      li {
        background-color: $PRIMARY;
        height: 4px;
      }
      .carouselItem {
        padding-bottom: 2rem;
        .itemWrapper {
          .testimonial {
            display: block;
            @include row();
            align-items: center;
            margin-bottom: 2rem;
            img {
              height: 64px;
              width: 64px;
              border-radius: 50%;
            }
            .info {
              margin-left: 1rem;
              @include column();
              .name {
                @include h4();
              }
              .company {
                @include normal();
                color: $GRAY800;
              }
            }
          }
          .quote {
            color: $GRAY800;
            padding-right: 2rem;
          }
        }
      }
    }
  }
}
